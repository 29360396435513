<a id="btnDownload" class="d-none" href="javascript:void('');" download="feedbacks.xlsx">DownloadFakeBtn</a>

<section class="single-page">

    <div class="hero">
        <img src="assets/images/newBackground.png" alt="Clic" />
    </div>

    <div class="list-featured">
        <div class="item" *ngIf="permission.avaliation || user.profile === 'user'">
            <a href="javascript:void('');" [routerLink]="['/avaliation']">
                <svg-icon src="assets/svg/avaliation.svg"></svg-icon>
                <span>{{'avaliacao'|translate}}</span>
            </a>
        </div>
        <div class="item" *ngIf="permission.newFeedback">
            <a href="javascript:void ('');" (click)="newFeedback()">
                <svg-icon src="assets/svg/feedback.svg"></svg-icon>
                <span>{{'novos'|translate}}<br>Feedbacks</span>
            </a>
        </div>
        <div class="item" *ngIf="permission.config">
            <a href="javascript:void('');" [routerLink]="['/config']">
                <svg-icon src="assets/svg/configav.svg"></svg-icon>
                <span>{{'configuraravaliacao'|translate}}</span>
            </a>
        </div>
        <div class="item" *ngIf="permission.user">
            <a href="javascript:void('');" [routerLink]="['/user']">
                <svg-icon src="assets/svg/userconfig.svg"></svg-icon>
                <span>{{'users'|translate}}</span>
            </a>
        </div>
        <div class="item" *ngIf="user.profile !== 'user'">
            <a href="javascript:void('');" [routerLink]="['/dashboard']">
                <svg-icon src="assets/svg/dashboard.svg"></svg-icon>
                <span>Dashboard</span>
            </a>
        </div>
    </div>

</section>


<section style="background: #f5f3ec;" class="home">
    <div class="row" style="row-gap: 20px" *ngIf="this.showDashboard && user.profile !== 'user'">
        <div class="col-md-7">
            <div class="home_card" *ngIf="user.profile !== 'admin'">
                <div class="avaliation">
                    <h3 class="home_title"><b>{{'avaliacaopendente'|translate}}</b></h3>
                    <div class="home_subtitle"><a [routerLink]="['/avaliation']">{{'vejatodas'|translate}}</a></div>
                </div>
                <div class="table-responsive">
                    <table class="table table_home">
                        <thead>
                            <tr>
                                <th scope="col">{{'nome'|translate}}</th>
                                <th scope="col" class="text-center">Status</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td *ngIf="isNullOrUndefined(userEvaluationPage.results)" class="text-center"
                                    colspan="3">
                                    {{"noRecordFound" | translate}}
                                </td>
                            </tr>
                            <tr *ngFor="let x of userEvaluationPage.results |
                        paginate: {
                        id:'evaluation',
                        itemsPerPage: userEvaluationPage.pageSize,
                        currentPage: userEvaluationPage.currentPage,
                        totalItems: userEvaluationPage.totalCount}">
                                <td>{{x.user.name}}</td>
                                <td>
                                    <div class="status_col" [ngClass]="x.status">
                                        <span>{{getTranslate(x.status)}}</span>
                                    </div>
                                </td>
                                <td class="text-right">
                                    <a [routerLink]="['/avaliation/create/' + x.id]">
                                        <svg-icon src="assets/svg/arquive.svg"></svg-icon>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="text-right">
                    <pagination-controls id="evaluation" [previousLabel]="labels.previousLabel"
                        [nextLabel]="labels.nextLabel"
                        [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                        [screenReaderPageLabel]="labels.screenReaderPageLabel"
                        [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                        (pageChange)="pageChanged($event)"></pagination-controls>
                </div>
            </div>
            <div class="home_card" *ngIf="user.profile === 'admin'">
                <div class="avaliation">
                    <h3 class="home_title"><b>{{'avaliacoes'|translate}}</b></h3>
                </div>
                <div class="table-responsive">
                    <table class="table table_home">
                        <thead>
                            <tr>
                                <th scope="col" class="w-45">Gestores</th>
                                <th scope="col" class="text-center">Pendentes</th>
                                <th scope="col">&nbsp;</th>
                                <th scope="col" class="text-center">Não Finalizadas</th>
                                <th scope="col">&nbsp;</th>
                                <th scope="col" class="text-center">Finalizadas</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td *ngIf="isNullOrUndefined(adminDashboard.results)" class="text-center" colspan="5">
                                    {{"noRecordFound" | translate}}
                                </td>
                            </tr>
                            <tr *ngFor="let x of adminDashboard.results |
                        paginate: {
                        id:'evaluationAdmin',
                        itemsPerPage: adminDashboard.pageSize,
                        currentPage: adminDashboard.currentPage,
                        totalItems: adminDashboard.totalCount}">
                                <td>{{x.userName}}</td>
                                <td class="text-center text-white bg-danger">
                                    {{x.pending}}
                                </td>
                                <td>&nbsp;</td>
                                <td class="text-center bg-warning">
                                    {{x.doing}}
                                </td>
                                <td>&nbsp;</td>
                                <td class="text-center text-white bg-success">
                                    {{x.done}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="!isNullOrUndefined(adminDashboard.results)" class="text-right">
                    <pagination-controls id="evaluationAdmin" [previousLabel]="labels.previousLabel"
                        [nextLabel]="labels.nextLabel"
                        [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                        [screenReaderPageLabel]="labels.screenReaderPageLabel"
                        [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                        (pageChange)="pageChangedDashBoardAdmin($event)"></pagination-controls>
                </div>
            </div>
        </div>
        <div class="col-md-5">
            <div class="home_card h-100">
                <div class="home_heading mb-5">
                    <h3 class="home_title"><b>Status</b>&nbsp; | &nbsp;<span
                            class="home_subtitle">{{'avaliacaocompetencia'|translate}}</span>
                    </h3>
                    <div class="input-material m-0 w-50">
                        <select name="" id="" (change)="onChangeYear($event)">
                            <option value="all" disabled selected>{{'select'|translate}}</option>
                            <option *ngFor="let year of evaluationYears.year; let i = index" [value]="year">{{year}}
                            </option>
                        </select>
                        <label>Ano</label>
                    </div>
                </div>
                <div class="graph">
                    <div class="graph_col">
                        <svg-icon src="assets/svg/graph.svg"></svg-icon>
                        <b>{{dashboard.pending}}</b>
                        <span><a class="text-gray" [routerLink]="['/avaliation']" [queryParams]="{status: 'pending'}">
                                {{'pendente'|translate}}</a></span>
                    </div>
                    <div class="graph_col">
                        <svg-icon [svgStyle]="{'stroke':'#FAE130'}" src="assets/svg/graph.svg"></svg-icon>
                        <b>{{dashboard.doing}}</b>
                        <span><a class="text-gray" [routerLink]="['/avaliation']"
                                [queryParams]="{status: 'doing'}">{{'naofinalizada'|translate}}</a></span>
                    </div>
                    <div class="graph_col">
                        <svg-icon [svgStyle]="{'stroke':'#6EBE11'}" src="assets/svg/graph.svg"></svg-icon>
                        <b>{{dashboard.done}}</b>
                        <span><a class="text-gray" [routerLink]="['/avaliation']"
                                [queryParams]="{status: 'done'}">{{'finalizada'|translate}}</a></span>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="row" style="row-gap: 20px">
        <div class="col-12">
            <div class="home_card mt-sm-4">
                <div class="row no-gutters" style="row-gap: 15px">
                    <h3 class="home_title col"><b>{{'historicofeedback'|translate}}</b></h3>
                    <div class="row no-gutters col-lg-auto align-items-start" style="column-gap: 20px">
                        <div class="input-material flex-grow-1" *ngIf="user.profile !== 'user'">
                            <input id="keyword" [(ngModel)]="this.filter.keyword" class="form-control" name="type">
                            <label for="keyword"> {{'search'|translate}}</label>
                        </div>
                        <div class="flex-grow-1">
                            <app-date-picker [start]="this.filter.start" [end]="this.filter.end"
                                (selectDate)="selectedDate($event)"></app-date-picker>
                        </div>
                        <a href="javascript:void(0)" (click)="onFilterFeedback();this.filter.page = 1"
                            class="btn btn-primary flex-grow-1">{{'buscar'|translate}}</a>
                        <a href="javascript:void('');" class="btn btn-gray-fill" (click)="actionExcel()">
                            <svg-icon class="right" src="assets/svg/downloads.svg"></svg-icon>
                            {{'exportarexcel'|translate}}
                        </a>

                    </div>
                </div>
                <div class="feedback_content" *ngFor="let fObj of feedbackPage.results |
                paginate: {
                id:'feedback',
                itemsPerPage: feedbackPage.pageSize,
                currentPage: feedbackPage.currentPage,
                totalItems: feedbackPage.totalCount}">
                    <div class="card_mini">
                        <b>{{fObj.date| date:'dd':'UTC-0'}}</b>
                        <span>{{fObj.date| date:'MMM':'UTC-0'}}</span>
                        <span>{{fObj.date| date:'yyyy':'UTC-0'}}</span>
                    </div>
                    <div class="feedback_col">
                        <div class="card_full" *ngFor="let f of fObj.feedbacks">
                            <div class="top mb-3">
                                <div class="info" style="gap: 15px">
                                    <img class="feedback_img feedback_img-sm"
                                        [src]="isNullOrUndefined(f.user?.photo)?'assets/images/user.png':f.user?.photo"
                                        alt="photo">
                                    <div class="info_title">|{{f.user?.re}}| {{f.user?.name}}</div>
                                </div>
                                <div class="edit" *ngIf="user.profile !== 'user'">
                                    <a (click)="actionDetail(f)">
                                        <svg-icon src="assets/svg/edit.svg"></svg-icon>
                                    </a>
                                    <a (click)="actionDelete(f.id)">
                                        <svg-icon src="assets/svg/close-circle.svg"></svg-icon>
                                    </a>
                                </div>
                            </div>
                            <p class="feedback_text">{{f.description}}</p>
                            <div class="d-flex">
                                <p class="text-small">{{'criadopor'|translate}} <b>{{f.createdBy?.name}}</b>
                                    em {{f.createdAt| date:'dd/MM/yyyy'}}</p>
                            </div>
                            <div class="d-flex" *ngIf="!isNullOrUndefined(f.updatedBy?.name)">
                                <p class="text-small">Atualizado por <b>{{f.updatedBy?.name}}</b>
                                    em {{f.updatedAt| date:'dd/MM/yyyy'}}
                            </div>

                        </div>
                    </div>
                </div>
                <div class="text-right">
                    <pagination-controls id="feedback" [previousLabel]="labels.previousLabel"
                        [nextLabel]="labels.nextLabel"
                        [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                        [screenReaderPageLabel]="labels.screenReaderPageLabel"
                        [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                        (pageChange)="pageChangedFeedback($event)"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</section>

<app-feedback [user]="feedBack.user" [feedback]="feedBack"
    (updateList)="onFilterFeedback();this.filter.page = 1"></app-feedback>
