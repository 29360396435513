import {
  AfterViewInit,
  Component,
  ViewChild,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { BaseComponent } from '../../base/base.component';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../service/user.service';
import { DatePicked } from '../date-picker-custom/model/date-picked';
import {
  CheckDashboardGQL,
  CheckDashboardQuery,
  Feedback,
  FeedbackPage,
  ListUserEvaluationYearGQL,
  RemoveFeedbackGQL,
  SearchDashboardInput,
  SearchFeedbackGQL,
  SearchFeedbackInput,
  SearchFeedbackQuery,
  SearchUserEvaluationInput,
  SearchUserEvaluationsGQL,
  SearchUserEvaluationsQuery,
  User,
  UserByNameGQL,
  UserEvaluationAdminDashboard,
  UserEvaluationAdminDashboardGQL,
  UserEvaluationAdminDashboardPage,
  UserEvaluationAdminDashboardQuery,
  UserEvaluationDashboard,
  UserEvaluationDashboardGQL,
  UserEvaluationDashboardQuery,
  UserEvaluationListYear,
  UserEvaluationPage,
} from '../../../generated/graphql';
import { QueryRef } from 'apollo-angular';
import moment from 'moment';
import { ExcelService } from '../../service/excel.service';

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
    user: User = new User();
    permission: Permissions = new Permissions();
    userEvaluationDashboardQuery: QueryRef<UserEvaluationDashboardQuery>;
    userEvaluationAdminDashboardQuery: QueryRef<UserEvaluationAdminDashboardQuery>;
    searchFeedbackQuery: QueryRef<SearchFeedbackQuery>;
    searchUserEvaluationsQuery: QueryRef<SearchUserEvaluationsQuery>;
    checkDashboardQuery: QueryRef<CheckDashboardQuery>;
    userEvaluationPage: UserEvaluationPage = new UserEvaluationPage();
    filter: SearchFeedbackInput = new SearchFeedbackInput();
    dashboard: UserEvaluationDashboard = new UserEvaluationDashboard();
    adminDashboard: UserEvaluationAdminDashboardPage = new UserEvaluationAdminDashboardPage();
    feedbackPage: FeedbackPage = new FeedbackPage();
    feedBack: Feedback = new Feedback();
    pageAdminDashboard = 1;
    labels: any = {
        previousLabel: ' ',
        nextLabel: ' ',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };
    btnNew = false;
    showDashboard = true;
    searchUserEvaluationInput: SearchUserEvaluationInput = new SearchUserEvaluationInput();
    evaluationYears: UserEvaluationListYear = new UserEvaluationListYear();
    elFilter = new SearchDashboardInput();

  constructor(
    public router: Router,
    private userByNameGQL: UserByNameGQL,
    private searchFeedbackGQL: SearchFeedbackGQL,
    private removeFeedbackGQL: RemoveFeedbackGQL,
    private searchUserEvaluationsGQL: SearchUserEvaluationsGQL,
    private userEvaluationDashboardGQL: UserEvaluationDashboardGQL,
    private checkDashboardGQL: CheckDashboardGQL,
    private userEvaluationAdminDashboardGQL: UserEvaluationAdminDashboardGQL,
    private listUserEvaluationYearGQL: ListUserEvaluationYearGQL,
    public userService: UserService,
    public excelService: ExcelService,
    public translate: TranslateService
  ) {
    super(router, translate);
  }

  ngOnInit() {
    this.checkDashboard();
    this.getDashBoard();
    this.getEvaluationYears();
    this.userService.user.subscribe({
      next: (data) => {
        this.user = data as User;
        this.actionUserProfile();
      },
    });
    $('#sidebar').addClass('is-hide');
    this.filter.start = moment().subtract(30, 'days').utc(true).format();
    this.filter.end = moment().utc(true).format();
  }

  ngAfterViewInit() {
    this.userService.user.subscribe({
      next: (data) => {
        if (!this.isNullOrUndefined(data.id)) {
          this.btnNew =
            data.profile === 'local' || data.profile === 'administrator';
        }
        this.getEvaluationsUser();
        this.getFeedBack();
        if (data.profile === 'admin') {
          this.getDashBoardAdmin();
        } else {
          this.getDashBoard();
        }
      },
    });
  }

  ngOnDestroy(): void {
    super.destroyModal('modalFeedback');
    $('#sidebar').removeClass('is-hide');
  }

  getEvaluationsUser() {
    this.searchUserEvaluationInput.pageSize = 3;
    this.searchUserEvaluationInput.status = 'pending';
    this.searchUserEvaluationsQuery = this.searchUserEvaluationsGQL.watch({
      data: this.searchUserEvaluationInput,
    });
    this.searchUserEvaluationsQuery.valueChanges.subscribe(({ data }) => {
      this.userEvaluationPage =
        data.searchUserEvaluations as UserEvaluationPage;
    });
  }

  onFilter() {
    this.searchUserEvaluationsQuery.refetch({
      data: this.searchUserEvaluationInput,
    });
  }

  pageChanged(event) {
    this.searchUserEvaluationInput.page = event;
    this.onFilter();
  }

  selectedDate(event: DatePicked) {
    this.filter.start = moment(event.start).utc(true).format();
    this.filter.end = moment(event.end).utc(true).format();
  }

  getDashBoard() {
    console.log('DASHBOARD FILTER INPUT', this.elFilter);
    this.userEvaluationDashboardQuery = this.userEvaluationDashboardGQL.watch({
      data: this.elFilter,
    });
    this.userEvaluationDashboardQuery.valueChanges.subscribe(({ data }) => {
      this.dashboard = data.userEvaluationDashboard as UserEvaluationDashboard;
      console.log('DASHBOARD', this.dashboard);
    });
  }

  getDashBoardAdmin() {
    this.userEvaluationAdminDashboardQuery =
      this.userEvaluationAdminDashboardGQL.watch({
        data: this.pageAdminDashboard,
      });
    this.userEvaluationAdminDashboardQuery.valueChanges.subscribe(
      ({ data }) => {
        this.adminDashboard =
          data.userEvaluationAdminDashboard as UserEvaluationAdminDashboardPage;
      }
    );
  }

  pageChangedDashBoardAdmin(event: number) {
    this.pageAdminDashboard = event;
    this.userEvaluationAdminDashboardQuery.refetch({
      data: this.pageAdminDashboard,
    });
  }

  getFeedBack() {
    this.searchFeedbackQuery = this.searchFeedbackGQL.watch({
      data: this.filter,
    });
    this.searchFeedbackQuery.valueChanges.subscribe(({ data }) => {
      this.feedbackPage = data.searchFeedback as FeedbackPage;
    });
  }

  onFilterFeedback() {
    this.searchFeedbackQuery.refetch({ data: this.filter });
  }

  pageChangedFeedback(event: number) {
    this.filter.page = event;
    this.onFilterFeedback();
  }

  actionDelete(id: string) {
    super.confirmMessage(
      this.translate.instant('baseComponent.warningAttention'),
      this.translate.instant('confirmedFeedbackDeleted'),
      () => {
        this.removeFeedbackGQL.mutate({ id }).subscribe((result) => {
          this.searchFeedbackQuery.refetch({ data: this.filter });
          super.showMessage(
            this.translate.instant('baseComponent.success'),
            this.translate.instant('feedbackDeleted'),
            'success'
          );
        });
      }
    );
  }

  actionDetail(x: Feedback) {
    this.feedBack = x;
    this.showModal('modalFeedback');
  }

  newFeedback() {
    this.feedBack = new Feedback();
    this.showModal('modalFeedback');
  }
  actionUserProfile() {
    switch (this.user.profile) {
      case 'admin':
        this.permission = {
          avaliation: true,
          config: true,
          user: true,
          newFeedback: true,
        };
        break;
      case 'manager':
        this.permission = {
          avaliation: true,
          config: false,
          user: true,
          newFeedback: true,
        };
        break;
      case 'leader':
        this.permission = {
          avaliation: true,
          config: false,
          user: false,
          newFeedback: true,
        };
        break;
      case 'user':
        this.permission = {
          avaliation: false,
          config: false,
          user: false,
          newFeedback: false,
        };
        break;
    }
  }

  private checkDashboard() {
    this.checkDashboardQuery = this.checkDashboardGQL.watch();
    this.checkDashboardQuery.valueChanges.subscribe(({ data }) => {
      this.showDashboard = data.checkDashboard as boolean;
    });
  }

  actionExcel() {
    this.filter = {
      keyword: this.filter.keyword,
      start: this.filter.start,
      end: this.filter.end,
    };
    // console.log('this.excelFilter');
    // console.log(this.filter);
    this.excelService.getExcelFeedbacks(this.filter).subscribe({
      next: (data) => {
        this.downloadHandlerFile(data);
      },
      error: (err) => super.onError(err),
    });
  }

  downloadHandlerFile(e) {
    $('#btnDownload').attr('href', e);
    setTimeout(() => {
      $('#btnDownload')[0].click();
    });
  }

  getEvaluationYears() {
    this.listUserEvaluationYearGQL
      .watch()
      .valueChanges.subscribe(({ data }) => {
        this.evaluationYears = data.listUserEvaluationYear;
        // console.log('EVALUATION YEARS', this.evaluationYears);
      });
  }

  onChangeYear(event: Event) {
    if ((event.target as HTMLSelectElement).value !== 'all') {
      this.elFilter.year = (event.target as HTMLSelectElement).value;
    } else {
      this.elFilter.year = undefined;
    }
    this.userEvaluationDashboardQuery.refetch();
  }
}

export class Permissions {
  avaliation: boolean;
  config: boolean;
  user: boolean;
  newFeedback: boolean;

  constructor() {
    this.avaliation = false;
    this.config = false;
    this.user = false;
    this.newFeedback = false;
  }
}
